<template>
    <div class="know">
      <HeaderPic :topId="topId" :topTitle="topText"></HeaderPic>
      <div class="know-main">
        <div class="know-left">
          <ul class="know-table-list">
            <li :class="{ active: ind == index }" @click="change(index)" v-for="(item,index) of tableList" :key="item">
              <img v-if="ind == index" src="../assets/img/know-school.png" alt=""><span>{{item}}</span>
            </li>
          </ul>
        </div>
        <div class="know-right">
          <div class="know-title">
            {{ind==-1?'党支部':tableList[ind]}}
            <img src="../assets/img/know-right-title.png" alt="">
          </div>
          <!--  -->
          <PartyItem ref="child" :conList="conList" :detailObj="detailObj" :tableList="tableList"></PartyItem>
          <el-pagination v-if="pageShow" :current-page.sync="pageNum" :page-size="pageSize" @current-change="handleCurrentChange" layout="prev, pager, next" :total="total"></el-pagination>
        </div>
      </div>
    </div>
  </template>
  <script>
      import HeaderPic from '../components/HeaderPic.vue'
      import PartyItem from '../components/Party/PartyItem.vue'
      import {
          partyBranch,
          partyBranchDetail,
          increamPageView,
          getPageViewByNameAndId
      } from "@/request/api";
      export default {
          components: {
              HeaderPic,
              PartyItem,
          },
          data() {
              return {
                  topPicture: '',
                  topText: '',
                  topId: '',
                  tableList: [],
                  ind: -1,
                  detailObj: {},
                  conList: [{
                      id: 1,
                      type: 1,
                      from: '党委宣传部',
                      time: '2022.10.24',
                      img: require('../assets/img/hour.jpg'),
                      title: '“学党史、悟思想、办实事、开新局”',
                      con: '为进一步学习贯彻习近平总书记“七一”重要讲话精神和高陵嘉园小学党史学习教育领导小组关于党史学习教育专题组织生活会的通知要求，7月12日下午，高陵嘉园小学党支部在支部活动室召开了“学党史、悟思想、办实事、开新局”专题组织生活会，机关党委张步新书记列席指…'
                  }, {
                      id: 2,
                      type: 2,
                      from: '党委宣传部',
                      time: '2022.10.24',
                      img: require('../assets/img/hour.jpg'),
                      title: '党史引领竞讲坛 微课舞台露头角',
                      con: '为进一步学习贯彻习近平总书记“七一”重要讲话精神和高陵嘉园小学党史学习教育领导小组关于党史学习教育专题组织生活会的通知要求，7月12日下午，高陵嘉园小学党支部在支部活动室召开了“学党史、悟思想、办实事、开新局”专题组织生活会，机关党委张步新书记列席指…'
                  }, {
                      id: 3,
                      type: 2,
                      from: '党委宣传部',
                      time: '2022.10.24',
                      img: require('../assets/img/hour.jpg'),
                      title: '觉醒时代 建党先声',
                      con: '为进一步学习贯彻习近平总书记“七一”重要讲话精神和高陵嘉园小学党史学习教育领导小组关于党史学习教育专题组织生活会的通知要求，7月12日下午，高陵嘉园小学党支部在支部活动室召开了“学党史、悟思想、办实事、开新局”专题组织生活会，机关党委张步新书记列席指…'
                  }, ],
                  // 分页
                  total: 0,
                  pageNum: 1,
                  pageSize: 10,
                  pageShow: true,
                  // 浏览器宽度
                  screenWidth: 0,
              }
          },
          created() {
              this.initTableList();
              this.partyBranch();
          },
          mounted() {
              if (this.$route.query.id) {
                  this.ind = this.$route.query.id - 1;
                  this.change(this.ind);
              }
          },
          watch: {
              $route: {
                  handler(val, oldval) {
                      if (val.query.id) {
                          this.ind = this.$route.query.id - 1;
                          this.change(this.ind);
                      }
  
                      // this.partyBranch();
                  },
                  // 深度观察监听
                  deep: true
              }
          },
          methods: {
              initTableList() {
                  this.tableList = [];
                  for (let item of this.headerList[2].content) {
                      this.tableList.push(item.text)
                  }
                  this.topText = this.headerList[2].title;
                  this.topId = this.headerList[2].id;
              },
              change(index, type) {
                  if (!type) {
                      this.ind = index;
                      this.$refs.child.change();
                      this.partyBranch();
                  }
                  this.detailObj = {};
                  this.pageShow = true;
              },
              // 分页
              handleCurrentChange(val) {
                  this.pageNum = val;
                  this.partyBranch();
              },
              partyBranch() {
                  const _this = this;
                  let data = {
                      type: parseInt(_this.ind) + 1, //活动类型（1：党建活动；2：党员活动）
                      pageNum: _this.pageNum,
                      pageSize: _this.pageSize
                  }
                  partyBranch(data).then(res => {
                      _this.conList = res.rows;
                      _this.total = res.total;
                      if (_this.total == 0) _this.pageShow = false;
                      else _this.pageShow = true;
                  }).catch((error) => {
                      // error
                      console.log(error)
                  })
              },
              getDetail(id) {
                  const _this = this;
                  // _this.increamPageView(id);
                  let data = {
                      id: id
                  }
                  partyBranchDetail(data).then(res => {
                      _this.detailObj = res;
                      _this.getPageViewByNameAndId(id);
                      _this.pageShow = false;
                  }).catch((error) => {
                      // error
                      console.log(error)
                  })
              },
              //浏览量的增加
              increamPageView(id) {
                  // const _this = this;
                  let data = {
                      pageName: this.topText,
                      pageId: id
                  };
                  increamPageView(data).then(res => {
                      console.log(res);
                  }).catch((error) => {
                      // error
                      console.log(error)
                  })
              },
              //获取当前页面浏览量
              getPageViewByNameAndId(id) {
                  const _this = this;
                  let data = {
                      pageName: this.topId,
                      pageId: id
                  };
                  getPageViewByNameAndId(data).then(res => {
                      _this.detailObj.lookNum = res.data;
                  }).catch((error) => {
                      // error
                      console.log(error)
                  })
              },
          }
      }
  </script>
  <style scoped lang="scss">
      @import "../../public/css/frame.scss";
  </style>